var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article is-flex is-flex-direction-column"},[(_vm.fromPage === 'home')?_c('router-link',{staticClass:"article__image is-relative is-block",attrs:{"to":`/articole/articol/${_vm.formatArticleTitle(_vm.article.titlu)}-${
				_vm.article.id
			}/`}},[_c('img',{attrs:{"src":`${_vm.databaseLink}/${_vm.article.imagine.url}`,"alt":_vm.article.titlu}}),_c('div',{staticClass:"article__overlay is-flex is-hidden-touch is-align-items-center is-justify-content-center"},[_c('ion-icon',{attrs:{"name":"link-outline"}})],1)]):_c('router-link',{staticClass:"article__image is-relative is-block",attrs:{"to":`${_vm.$route.path}articole/${_vm.formatArticleTitle(_vm.article.titlu)}-${
				_vm.article.id
			}/`}},[_c('img',{attrs:{"src":`${_vm.databaseLink}/${_vm.article.imagine.url}`,"alt":_vm.article.titlu}}),_c('div',{staticClass:"article__overlay is-flex is-hidden-touch is-align-items-center is-justify-content-center"},[_c('ion-icon',{attrs:{"name":"link-outline"}})],1)]),_c('ul',{staticClass:"article__meta is-flex is-justify-content-center py-3"},[_c('li',{staticClass:"is-flex is-align-items-center mr-3"},[_c('div',{staticClass:"icon"},[_c('ion-icon',{attrs:{"name":"calendar-clear-outline"}})],1),_c('span',[_vm._v(_vm._s(_vm.moment(_vm.article.published_at).format('DD.MM.YYYY')))])]),(_vm.article.autor && _vm.article.autor.length > 0)?_c('li',{staticClass:"is-flex is-align-items-center mr-3"},[_c('div',{staticClass:"icon"},[_c('ion-icon',{attrs:{"name":"person-outline"}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.article.autor)+" ")])]):_vm._e()]),_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-space-between",staticStyle:{"height":"100%"}},[(_vm.fromPage === 'home')?_c('router-link',{staticClass:"article__title is-block mb-3 has-text-centered",attrs:{"to":`/articole/articol/${_vm.formatArticleTitle(_vm.article.titlu)}-${
					_vm.article.id
				}/`}},[_vm._v(_vm._s(_vm.article.titlu))]):_c('router-link',{staticClass:"article__title is-block mb-3 has-text-centered",attrs:{"to":`${_vm.$route.path}articole/${_vm.formatArticleTitle(
					_vm.article.titlu
				)}-${_vm.article.id}/`}},[_vm._v(_vm._s(_vm.article.titlu))]),_c('markdown-it-vue',{staticClass:"article__description",attrs:{"content":_vm.article.descriere_scurta}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }